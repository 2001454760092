import { useState } from 'react'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import play from '../assets/play.png'
import logo from '../assets/logo.png'
import comma from '../assets/comma.png'
import close from '../assets/close-black.png'

import mechanic from '../assets/images/video/mechanic.jpg'
import birth from '../assets/images/video/birth.png'
import niger from '../assets/images/video/niger.jpg'

import quote from '../assets/images/quote.jpg'

import ourworks1 from '../assets/images/ourworks1.jpg'
import ourworks2 from '../assets/images/ourworks2.jpg'
import ourworks3 from '../assets/images/ourworks3.jpg'
import ourworks4 from '../assets/images/ourworks4.jpg'
import ourworks5 from '../assets/images/ourworks5.jpg'
import ourworks6 from '../assets/images/ourworks6.jpg'

import canada from '../assets/brandlogo/canada-gray.jpg'
import gavi from '../assets/brandlogo/gavi-gray.png'
import uncerf from '../assets/brandlogo/uncerf-gray.png'
import unicef from '../assets/brandlogo/unicef-gray.png'
import yoma from '../assets/brandlogo/yoma-gray.png'
import okayafrica from '../assets/brandlogo/okayafrica-gray.png'
import mlogo from '../assets/brandlogo/mlogo-gray.png'
import lagos from '../assets/brandlogo/lagos-gray.png'
import africanjournal from '../assets/brandlogo/african-journal.png'


const logos = [
  { src: canada, alt: 'canada' },
  { src: gavi, alt: 'gavi' },
  { src: unicef, alt: 'unicef' },
  { src: lagos, alt: 'lagos state' },
  { src: uncerf, alt: 'uncerf' },
  { src: yoma, alt: 'yoma' },
  { src: okayafrica, alt: 'okay africa' },
  { src: africanjournal, alt: 'african journal' },
  { src: mlogo, alt: 'Logo 9' },
];

const Homepage = () => {

	const settings = {
	    infinite: true,
	    slidesToShow: 6,
	    slidesToScroll: 1,
	    autoplay: true,
	    speed: 2000,
	    autoplaySpeed: 2000,
	    responsive: [
	      {
	        breakpoint: 1024,
	        settings: {
	          slidesToShow: 3,
	          slidesToScroll: 1,
	          infinite: true,
	          autoplay: true,
	          speed: 2000,
	          autoplaySpeed: 2000,
	        },
	      },
	      {
	        breakpoint: 600,
	        settings: {
	          slidesToShow: 2,
	          slidesToScroll: 1,
	          infinite: true,
	          autoplay: true,
	          speed: 2000,
	          autoplaySpeed: 2000,
	        },
	      },
	      {
	        breakpoint: 480,
	        settings: {
	          slidesToShow: 1,
	          slidesToScroll: 1,
	          infinite: true,
	          autoplay: true,
	          speed: 2000,
	          autoplaySpeed: 2000,
	        },
	      },
	    ],
	};


	// const [isOpen, setIsOpen] = useState(true);

	// const closePopup = () => {
	//     setIsOpen(false);
	// };


	// // POPUP email submit form
	// const form = useRef();

	// const sendEmail = (e) => {
	//     e.preventDefault();

	//     emailjs.sendForm('service_9jjxhze', 'template_3qas9qp', form.current, 'RbG8xAVSd5MnOHIwn')
	//       .then((result) => {
	//           console.log(result.text);
	//       }, (error) => {
	//           console.log(error.text);
	//       });
	// };

	return (
		<>

			{/*{isOpen && (
		        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-20">
		          <div className="bg-white w-[500px] p-8 shadow-md">
		            <div className="flex justify-end pb-10">
		              <img 
		               	className="xl:h-[30px] xl:w-[30px] w-10 h-10 cursor-pointer"
		               	src={close} 
		               	alt="close" 
		               	onClick={closePopup}
		              />
		            </div>
		            <h2 className="text-2xl font-bold mb-4" style={{fontFamily: "Futura"}}>Subscribe to Our Newsletter</h2>
		            <form ref={form} onSubmit={sendEmail} >
		              <div className="mb-4">
		                <input
		                  className="w-full border p-2 rounded"
		                  type="email"
		                  name="message"
		                  id="email"
		                  placeholder="Enter your email"
		                  style={{fontFamily: "Futura"}}
		                />
		              </div>
		              <button
		                className="bg-black text-white w-full py-2 px-4 rounded hover:bg-black-600"
		                type="submit"
		                style={{fontFamily: "Futura"}}
		              >
		                Subscribe
		              </button>
		            </form>
		          </div>
		        </div>
		    )}*/}

			<div className="relative h-screen -mt-[150px] bg-black bg-opacity-50">
				<video 
					loop 
					muted 
					autoPlay 
					className="absolute inset-0 object-cover w-full h-full"
				>
				    <source src="https://res.cloudinary.com/dfs540rt8/video/upload/v1705733063/recreate-africa/hero_no9b3k.mp4" type="video/mp4" />
				</video>

				<div className="absolute inset-0 bg-black bg-opacity-50"></div>
				<div className="absolute bottom-0 left-0 p-8 text-white xl:w-2/5 lg:w-2/5 md:w-3/5 sm:w-3/5 w-full ">
					{/*<p className="text-2xl font-bold capitalize tracking-wider" style={{fontFamily: "Futura"}}>celebrating african stories</p>*/}
					<p style={{fontFamily: "Futura"}}>Through effective storytelling, we celebrate Africa and document authentic stories of impact.</p>
				</div>
			</div>

			{/*SECTION*/}
			<div className="xl:px-20 xl:py-[100px] lg:px-20 lg:py-40 md:py-20 md:px-10 px-4 py-[60px] grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 relative"
				style={{ backgroundImage: `url(${quote})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
				<div className="bg-black bg-opacity-70 absolute inset-0"></div>
				<div className="xl:order-1 lg:order-1 md:order-1 order-2 relative z-10 xl:w-full lg:w-5/6 md:w-full w-full">
					<div className="flex flex-row mb-2 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
						<img className="w-[30px] h-[50px]" src={comma} alt="comma" />
						<img className="w-[30px] h-[50px]" src={comma} alt="comma" />
					</div>
					<p className="text-white text-center text-lg" style={{fontFamily: "Futura"}}>Recreate Africa is a media production & storytelling company on a mission to elevate African stories and document authentic stories of impact. We work with leading organizations and international firms and have gained credits from like UNICEF, SIDA, GAVI, CANADA, UNCERF, OkayAfrica, African Journal of Photography, YOMA, Lagos State Government, Miva University and other reputable institutions.</p>
					<div className="flex flex-row float-right">
						<img className="w-[30px] h-[50px]" src={comma} alt="comma" />
						<img className="w-[30px] h-[50px]" src={comma} alt="comma" />
					</div>
				</div>
				<div className="xl:order-2 lg:order-2 md:order-2 order-1 relative z-10 flex xl:justify-end lg:justify-end md:justify-end justify-center xl:mt-0 lg:mt-0 md:mt-0 mt-10">
					<img className="my-auto max-h-14" src={logo} alt="logo" />
				</div>
			</div>


			{/*OUR TEAM*/}
			<div className="xl:py-20 lg:py-20 md:py-20 py-10 overflow-hidden bg-black">
				<p className="uppercase mb-20 text-center text-white xl:text-[2em] lg:text-[2em] md:text-[2em] text-xl font-semibold tracking-[3px]">who we work with</p>
			    <Slider {...settings}>
			        {logos.map((logo, index) => (
			          <div key={index} className="text-center">
			            <img
			              src={logo.src}
			              alt={logo.alt}
			              className="mx-auto xl:max-h-16 lg:max-h-16 md:max-h-10 max-h-10 filter grayscale-100"
			            />
			          </div>
			        ))}
			    </Slider>
			</div>

			<div className="bg-black xl:px-10 lg:px-10 md:px-6 px-4 xl:pb-[50px] lg:pb-[100px] md:pb-[100px] pb-[50px]
				xl:pt-[100px] lg:pt-[100px] md:pt-[50px] pt-[50px] ">
				<div className="flex justify-between">
					<div className="">
						<p className="capitalize text-2xl text-white mb-5 font-semibold tracking-[3px]" style={{fontFamily: "Futura"}}>our work</p>
					</div>
					<div className="">
						<a href="/projects" className="border border-white xl:px-10 xl:py-4 lg:px-10 lg:py-4 px-4 py-1 text-white hover:bg-white hover:text-black transition rounded-[50px]">view more</a>
					</div>
				</div>
				<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-x-5 lg:gap-x-5 md:gap-x-5 ">
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<div className="aspect-ratio-16/9">
							<img src={ourworks3} className="object-cover w-full bg-no-repeat" alt="" />
						</div>
					</div>
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<div className="aspect-ratio-16/9">
							<img src={ourworks5} className="object-cover w-full bg-no-repeat" alt="" />
						</div>
					</div>
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<div className="aspect-ratio-16/9">
							<img src={ourworks4} className="object-cover w-full bg-no-repeat" alt="" />
						</div>
					</div>
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<div className="aspect-ratio-16/9">
							<img src={ourworks2} className="object-cover w-full bg-no-repeat" alt="" />
						</div>
					</div>
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<div className="aspect-ratio-16/9">
							<img src={ourworks6} className="object-cover w-full bg-no-repeat" alt="" />
						</div>
					</div>
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<div className="aspect-ratio-16/9">
							<img src={ourworks1} className="object-cover w-full bg-no-repeat" alt="" />
						</div>
					</div>

					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<a href="https://www.youtube.com/watch?v=MMHwydXgvRs" target="_blank" className="group relative overflow-hidden">
							<div className="aspect-ratio-16/9">
								<img src={birth} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
							<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
								<img
						            src={play}
						            className="bg-auto w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-300 opacity-100"
            						alt=""
						        />
							</div>
						</a>
						{/*<a href="">
							<p className="uppercase xl:text-lg text-base text-white mt-2 tracking-wider" style={{fontFamily: "Futura"}}>MY NAME: The Documentary</p>
						</a>*/}
					</div>
					<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
						<a href="https://www.youtube.com/watch?v=TBtEY8-0YT0" target="_blank" className="group relative overflow-hidden">
							<div className="aspect-ratio-16/9">
								<img src={mechanic} className="object-cover w-full" alt="" />
							</div>
							<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
								<img
						            src={play}
						            className="w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-300 opacity-100"
            						alt=""
						        />
							</div>
						</a>
						{/*<a href="">
							<p className="uppercase xl:text-lg text-base text-white mt-2 tracking-wider" style={{fontFamily: "Futura"}}>Local Female Area Mechanics (LAMs), Northwest in Nigeria: A UNICEF documentary film</p>
						</a>*/}
					</div>
					<div className="xl:mb-10 md:mb-0 mb-10">
						<a href="https://www.youtube.com/watch?v=rKdAOQ09fi8" target="_blank" className="group relative overflow-hidden">
							<div className="aspect-ratio-16/9">
								<img src={niger} className="object-cover w-full" alt="" />
							</div>
							<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
								<img
						            src={play}
						            className="w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-800 opacity-100"
            						alt=""
						        />
							</div>
						</a>
						{/*<a href="">
							<p className="uppercase xl:text-lg text-base text-white mt-2 tracking-wider" style={{fontFamily: "Futura"}}>WATER'S EDGE NIGER - Inspiring story of resilience and hope in Nigeria</p>
						</a>*/}
					</div>
				</div>
			</div>



			{/*OUR VALUES*/}
			<div className="xl:h-[500px] lg:h-[500px] w-full relative flex flex-col items-center justify-center" 
				style={{ backgroundImage: `url(${ourworks3})`, 
					backgroundSize: 'cover', 
					backgroundPosition: 'center'
				}}
			>
				<div className="bg-black bg-opacity-70 absolute inset-0"></div>
				<div className="mb-20">
					<p className="uppercase text-center text-left xl:text-[2em] lg:text-[2em] md:text-[2em] text-xl tracking-[3px] text-white relative font-semibold "
						style={{fontFamily: "Futura"}}>
						our approach
					</p>
				</div>
				<div className="flex items-center justify-center xl:px-40 lg:px-20 px-4">
					<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 xl:space-x-5 lg:space-x-5 md:space-x-5 sm:space-x-5 relative">
						<div className="mb-10 bg-[#1d1e21] shadow-md xl:p-10 lg:p-10 md:p-10 p-8 text-center text-white hover:bg-white hover:text-black cursor-pointer transition">
							<p className=" uppercase font-semibold">authenticity</p>
							<p 
								className="text-lg mt-2" 
								style={{fontFamily: "Futura"}}>
								Accurately representing Africa through a genuine approach to show it's contemporary reality. We do not compromise our standards but we are flexible enough to redesign our methods in sync with trends and popular culture.
							</p>
						</div>
						<div className="mb-10 bg-[#1d1e21] shadow-md xl:p-10 lg:p-10 md:p-10 p-8 text-center text-white hover:bg-white hover:text-black cursor-pointer transition">
							<p className="uppercase font-semibold">collaboration & co-creation</p>
							<p 
								className="text-lg mt-2" 
								style={{fontFamily: "Futura"}}>
								We collaborate closely with you throughout the storytelling process, indentifying your needs, providing innovative suggestions and ensure the final product aligns with your vision.
							</p>
						</div>
						<div className="mb-10 bg-[#1d1e21] shadow-md xl:p-10 lg:p-10 md:p-10 p-8 text-center text-white hover:bg-white hover:text-black cursor-pointer transition">
							<p className="uppercase font-semibold">creativity & innovation</p>
							<p 
								className="text-lg mt-2" 
								style={{fontFamily: "Futura"}}>
								We make use of unconventional techniques and artistic patterns while going about storytelling. It is what makes us stand out in the crowded media landscape.
							</p>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default Homepage