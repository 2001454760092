import {Helmet} from "react-helmet";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import first from "../assets/images/about/first.jpg"
import second from "../assets/images/about/second.jpg"
import third from "../assets/images/about/third.jpg"
import forth from "../assets/images/about/forth.jpg"
import fifth from "../assets/images/about/fifth.jpg"
import sixth from "../assets/images/about/sixth.jpg"
import seventh from "../assets/images/about/seventh.jpg"
import eighth from "../assets/images/about/eighth.jpg"

const About = () => {
	return (
		<>
			<Helmet>
                <title>About us | Recreate Africa</title>
            </Helmet>
			<div className="-mt-[140px] bg-black pt-40 pb-2">
				<div className="text-center xl:px-[400px] lg:px-[400px] md:px-20 mb-20 xl:px-0 lg:px-0 px-4">
					<p className="capitalize text-center xl:text-[2em] lg:text-[2em] md:text-[2em] text-xl tracking-[3px] text-white relative font-semibold mb-10">about us</p>
					<p className="mb-10 xl:text-xl lg:text-xl text-lg text-white" style={{fontFamily: "Futura"}}>
						We are an innovative media production & storytelling company. Our goal is to celebrate Africa and tell 
						authentic stories of impact using our mastery of brilliant film making and efficient communication process. Recreate Africa 
						is based in Nigeria but has work spanning across every region of Africa.</p>
				</div>
				<div className="">
				    <Swiper
				        slidesPerView={4}
				        spaceBetween={5}
				        centeredSlides={false}
				        autoplay={{
					        delay: 3000, // Set the delay between slides in milliseconds (e.g., 3000 for 3 seconds)
					        disableOnInteraction: true, // Continue autoplay even when the user interacts with the slider
					    }}
				        pagination={{
				          clickable: true,
				        }}
					    modules={[Autoplay, Pagination, Navigation]}
				        className="mySwiper"
				    >
				        <SwiperSlide><img src={first} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={second} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={third} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={forth} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={fifth} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={sixth} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={seventh} alt="slide" /></SwiperSlide>
				        <SwiperSlide><img src={eighth} alt="slide" /></SwiperSlide>
				    </Swiper>
				</div>
			</div>

		</>
	)
}

export default About