import phone from '../assets/phone.png'
import email from '../assets/email.png'
import logo from '../assets/logo.png'
import instagram from "../assets/instagram.png"
import linkedin from "../assets/linkedin.png"
import youtube from "../assets/youtube.png"
import twitter from "../assets/twitter.png"

const Footer = () => {
	return (
		<>
			<div className="xl:px-10 lg:px-10 md:px-10 px-5 py-8 bg-[#1d1e21]">
				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
					<div className="justify-start xl:w-4/5 lg:w-4/5">
						<div>
							<p className="xl:mb-0 lg:mb-0 md:mb-0 mb-5 uppercase text-xl xl:text-3xl lg:text-3xl tracking-[2px] xl:tracking-[10px]">
								<a href="/" className="text-white">
									<img className="xl:hidden lg:hidden md:hidden block xl:w-[200px] lg:w-[200px] md:w-[200px] w-[100px]" src={logo} alt="logo" />
								</a>
							</p>
							<p className="text-white text-lg" style={{fontFamily: "Futura"}}>Through effective and advanced storytelling we document the stories that are most important to you and our world with the best tools. Our award winning storytellers and creators with a mission to elevate authentic African stories, are eager to help you make a difference.</p>
						</div>

						<div className="xl:mt-10 lg:mt-10 md:mt-10 mt-10">
							<a href="mailto:hello@recreateafrica.org" className="capitalize px-10 py-4 bg-white text-black hover:bg-black hover:text-white font-semibold transition rounded-[50px]"
								style={{fontFamily: "Futura"}}>mail us</a>
						</div>
					</div>

					<div className="xl:mb-5 lg:mb-5 md:mb-5 mb-5 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
						<div className="flex items-center xl:justify-end lg:justify-end md:justify-end xl:mb-20 lg:mb-20 md:mb-20 mb-10">
							<p className="mb-5 uppercase text-xl xl:text-3xl lg:text-3xl tracking-[2px] xl:tracking-[10px]">
								<a href="/" className="text-white">
									<img className="xl:block lg:block md:block hidden" width="200" height="200" src={logo} alt="logo" />
								</a>
							</p>
						</div>

						{/*<div className="flex items-center xl:justify-end lg:justify-end md:justify-end">
							<img className="ml-2" width="30" height="30" src={email} alt="new-post--v2"/>
							<a href="mailto:hello@recreateafrica.org" className="ml-2 xl:mt-2">
								<p className="text-white text-lg" style={{fontFamily: "Futura"}}>hello@recreateafrica.org</p>
							</a>
							<a href="tel:+2348087149804" className="ml-2">
								<p className="text-white text-lg" style={{fontFamily: "Futura"}}>+234 808 714 9804</p>
							</a>
						</div>*/}
						<div className="flex xl:flex-row lg:flex-row flex-col items-center xl:justify-end lg:justify-end md:justify-end">
							<div className="flex items-center mb-2">
								<img className="ml-2" width="30" height="30" src={email} alt="new-post--v2"/>
								<a href="mailto:hello@recreateafrica.org" className="ml-2 xl:mt-2">
									<p className="text-white text-lg" style={{fontFamily: "Futura"}}>hello@recreateafrica.org</p>
								</a>
							</div>
							<div className="flex items-center mb-2">
								<img className="ml-2" width="30" height="30" src={phone} alt="phone"/>
								<a href="tel:+2348087149804" className="ml-2 mr-2 mr-2">
									<p className="text-white text-lg" style={{fontFamily: "Futura"}}>+234 808 714 9804</p>
								</a>
							</div>
							<div className="flex items-center mb-2">
								<a href="https://www.linkedin.com/company/recreate-africa" target="_blank">
									<img className="cursor-pointer mr-2" width="30" height="30" src={linkedin} alt="linkedin"/>
								</a>
								<a href="https://www.instagram.com/recreateafrica" target="_blank">
									<img className="cursor-pointer mr-2" width="30" height="30" src={instagram} alt="instagram"/>
								</a>
								<a href="https://www.youtube.com/@recreateafricaprojects" target="_blank">
									<img className="cursor-pointer mr-2" width="40" height="40" src={youtube} alt="youtube"/>
								</a>
								<a href="https://www.twitter.com/recreateafrica_" target="_blank">
									<img className="cursor-pointer mr-2" width="30" height="30" src={twitter} alt="twitter"/>
								</a>
							</div>
							{/*<div className="flex items-center">
								<a href="https://www.instagram.com/recreateafrica" target="_blank">
									<img className="cursor-pointer mr-2" width="30" height="30" src={instagram} alt="instagram-new--v1"/>
								</a>
							</div>*/}
						</div>
						{/*<div className="flex items-center xl:justify-end lg:justify-end md:justify-end xl:mt-10 lg:mt-10 md:mt-5 mt-5">
							<a href="https://www.linkedin.com/company/recreate-africa" target="_blank">
								<img className="cursor-pointer mr-2" width="30" height="30" src={linkedin} alt="linkedin"/>
							</a>
							<a href="https://www.instagram.com/recreateafrica" target="_blank">
								<img className="cursor-pointer mr-2" width="30" height="30" src={instagram} alt="instagram-new--v1"/>
							</a>
						</div>*/}
					</div>
				</div>
				<div className="mt-10 text-center">
					<p className="capitalize text-white text-lg" style={{fontFamily: "Futura"}}>copyright © 2024 recreate africa. all rights reserved.</p>
				</div>
			</div>
		</>
	)
}

export default Footer