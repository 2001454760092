import React, { useState, useEffect } from 'react'
import menu from '../assets/menu.png'
import menuBlack from '../assets/menu-black.png'
import close from '../assets/close.png'
import logo from '../assets/logo.png'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };

      window.addEventListener('resize', handleResize);

      // Cleanup the event listener when the component is unmounted
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 

  return (
    <>
      <div className="relative z-50">
        <div className="flex p-2 xl:p-10 ">
          <div>
            <p className="uppercase text-xl xl:text-3xl lg:text-3xl tracking-[2px] xl:tracking-[10px]">
              <a href="/" className="text-white">
                <img className="xl:w-[200px] lg:w-[200px] md:w-[200px] w-[100px]" src={logo} alt="logo" />
              </a>
            </p>
          </div>
          <div className="ml-auto">
            <img 
              src={menu} 
              className="w-[30px] xl:w-[40px] h-[30px] xl:h-[40px] cursor-pointer" 
              alt="logo" 
              onClick={toggleMenu}
            />
          </div>
        </div>
      </div>
      {isMenuOpen && (
          <div className="fixed top-0 left-0 w-full h-screen bg-black opacity-75 flex justify-center items-center z-50">
            <div className="p-8 rounded-md">
                <div className="absolute top-4 right-4">
                    <img 
                      className="xl:h-[60px] xl:w-[60px] w-10 h-10 cursor-pointer"
                      src={close} 
                      alt="close" 
                      onClick={toggleMenu}
                  />
                </div>
              <ul>
                  <li><a className="text-white capitalize text-4xl hover:underline transition" href="/" style={{fontFamily: "Futura"}}>home</a></li>
                  <li><a className="text-white capitalize text-4xl hover:underline transition" href="/projects" style={{fontFamily: "Futura"}}>our Work</a></li>
                  <li><a className="text-white capitalize text-4xl hover:underline transition" href="/about" style={{fontFamily: "Futura"}}>About</a></li>
                  {/*<li><a className="text-white capitalize text-4xl hover:underline transition" href="/contact" style={{fontFamily: "Futura"}}>Contact</a></li>*/}
                </ul>
              </div>
            </div>
      )}
    </>
  )
}

export default Navbar 